/* eslint-disable react-refresh/only-export-components */
import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";

import UpdateFAQ from "../pages/FAQ/UpdateFAQ";
// import AdminSearch from '../pages/admin/Search/AdminSearch';

const PrivateRoute = React.lazy(() => import("./PrivateRoute"));
const UserAuth = React.lazy(() => import("./UserAuth"));

const UpdateProfile = React.lazy(
  () => import("../pages/Profile/UpdateProfile")
);
const CreatePartners = React.lazy(
  () => import("../pages/expenses/CreatePartners")
);
const EditPartners = React.lazy(() => import("../pages/expenses/EditPartners"));
const Expense = React.lazy(() => import("../pages/expenses/index"));

const Payment = React.lazy(() => import("../pages/payment/Payment"));
const Generalsetting = React.lazy(
  () => import("../pages/settings/Generalsetting")
);
const PerformanceNumber = React.lazy(
  () => import("../pages/target/PerformanceNumber")
);
const PaymentGateway = React.lazy(() => import("../pages/payment/PaymentGateway"));

const Commission = React.lazy(() => import("../pages/settings/Commission"));
const Target = React.lazy(() => import("../pages/target/Target"));

// Package
const Packages = React.lazy(() => import("../pages/packages"));
const CreatePackage = React.lazy(
  () => import("../pages/packages/CreatePackage")
);
const PackageDetails = React.lazy(
  () => import("../pages/packages/PackageDetails")
);
const Subscription = React.lazy(() => import("../pages/packages/Subscription"));
const UpdatePackage = React.lazy(
  () => import("../pages/packages/UpdatePackage")
);

// Documents
const CreateDocumentForm = React.lazy(
  () => import("../pages/documents/CreateDocumentForm")
);
const Documents = React.lazy(() => import("../pages/documents/index"));
const UpdateDocumentForm = React.lazy(
  () => import("../pages/documents/UpdateDocumentForm")
);
const ViewDocument = React.lazy(
  () => import("../pages/documents/ViewDocument")
);

// Faq
const CreateNewFAQ = React.lazy(() => import("../pages/FAQ/CreateNewFAQ"));
const ViewFAQFile = React.lazy(() => import("../pages/FAQ/ViewFAQFile"));
const FAQs = React.lazy(() => import("../pages/FAQ/FAQs.page"));
// const UpdateFAQ = React.lazy(() => import("../pages/FAQ/UpdateFAQ"));

// ThirdPartyCost
const CreateThirdpartycost = React.lazy(
  () => import("../pages/thirdPartyCost/CreateThirdpartycost")
);
const ThirdPartycost = React.lazy(
  () => import("../pages/thirdPartyCost/index")
);

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Register = React.lazy(() => import("../pages/auth/register/Register"));
const SalesRepsRegister = React.lazy(
  () => import("../pages/auth/register/SalesRepsRegistration")
);
const RecoverPassword = React.lazy(
  () => import("../pages/auth/RecoverPassword")
);
const PasswordReset = React.lazy(
  () => import("../pages/auth/PasswordReset") // Adjust path if needed
);
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// dashboard
const Dashboard = React.lazy(() => import("../pages/dashboard/"));

// users
const Users = React.lazy(() => import("../pages/users"));
const CreateUser = React.lazy(() => import("../pages/users/CreateUser"));
const UserInfoEditForm = React.lazy(
  () => import("../pages/users/UserInfoEditForm")
);

//team-manger
const TeamManager = React.lazy(() => import("../pages/teamManager"));
const TeamManagereditForm = React.lazy(
  () => import("../pages/teamManager/TeammanagerEdit")
);

//customers
const Customers = React.lazy(() => import("../pages/customers"));
const CreateCustomer = React.lazy(
  () => import("../pages/customers/CreateCustomer")
);
const CustomerInfoEditForm = React.lazy(
  () => import("../pages/customers/CustomerInfoEditForm")
);
const EditBusinessProfile = React.lazy(
  () => import("../pages/customers/EditBusinessProfile")
);
const ViewCustomerProfile = React.lazy(
  () => import("../pages/customers/ViewCustomerProfile")
);
const CustomersPayments = React.lazy(
  () => import("../pages/customers/payments/CustomersPayments")
);
const CustomerSingleInvoice = React.lazy(
  () => import("../pages/customers/singleCustomer/invoice")
);
const CustomerPackage = React.lazy(
  () => import("../pages/packages/customerPkg/PackageCustomer")
);
const CustomerSinglePayment = React.lazy(
  () => import("../pages/customers/singleCustomer/payment")
);
const ViewSingleCustomerInvoice = React.lazy(
  () => import("../pages/customers/singleCustomer/ViewSingleCustomerInvoice")
);
const AllCustomersInvoiceTable = React.lazy(
  () => import("../pages/customers/Invoices/AllCustomersInvoicesTable")
);
const CustomerViewProfileInvoice = React.lazy(
  () => import("../pages/customers/Invoices/ViewCustomerInvoice")
);
const ViewCustomerInvoice = React.lazy(
  () => import("../pages/customers/Invoices/ViewInvoice/ViewCustomerInvoice")
);

// Subscribe Packages
const SubscribePackages = React.lazy(
  () => import("../pages/packages/SubscribedPackages")
);

//sales Reps
const CreateSalesRep = React.lazy(
  () => import("../pages/saleReps/CreateSalesRep")
);
const SaleReps = React.lazy(() => import("../pages/saleReps"));
const SaleRepsInfoEditForm = React.lazy(
  () => import("../pages/saleReps/SaleRepsInfoEditForm")
);
const SalesRepsCustomer = React.lazy(
  () => import("../pages/saleReps/SingleSales/SingleSalesCustomer")
);
const SingleSalesRepsInvoice = React.lazy(
  () => import("../pages/saleReps/SingleSales/invoice")
);
const SingleSalesRepsPayment = React.lazy(
  () => import("../pages/saleReps/SingleSales/payment")
);
const ViewSaleRepProfile = React.lazy(
  () => import("../pages/saleReps/ViewSaleRepsProfile")
);
const SalesRepsPayment = React.lazy(
  () => import("../pages/saleReps/SalesRepsPayment")
);
const SalesInvoices = React.lazy(
  () => import("../pages/saleReps/SalesInvoices")
);
const SalesRepsEditProfile = React.lazy(
  () => import("../pages/saleReps/SalesRepsEditProfile")
);
const Sales_Earnings = React.lazy(
  () => import("../pages/saleReps/earnings/AllEarnings")
);
const MyEarnings = React.lazy(
  () => import("../pages/saleReps/earnings/MyEarnings")
);

const SalesRepsViewProfileInvoice = React.lazy(
  () => import("../pages/saleReps/viewSRComponents/SalesRepsViewProfileInvoice")
);
const SalesRepDocuments = React.lazy(
  () => import("../pages/saleReps/SingleSales/SaleRepDocuments")
);
const ViewInvoice = React.lazy(() => import("../pages/saleReps/ViewInvoice"));

// Reports
const Reports = React.lazy(() => import("../pages/reports/index"));
const VisibilityReportPage = React.lazy(
  () => import("../pages/reports/visibilityReportPage")
);

//Registration
const RegistrationPayment = React.lazy(
  () => import("../pages/payment/RegistrationPayment")
);
const RegPackages = React.lazy(
  () => import("../pages/auth/register/RegPackage")
);
const RegPayment = React.lazy(
  () => import("../pages/auth/register/RegPayment")
);
const AccessNewAccount = React.lazy(
  () => import("../pages/auth/register/RegAccess")
);
const LandingPage = React.lazy(() => import("../pages/auth/landingPageUi"));

// error pages
const Maintenance = React.lazy(() => import("../pages/error/Maintenance"));
const ComingSoon = React.lazy(() => import("../pages/error/ComingSoon"));
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error404Alt = React.lazy(() => import("../pages/error/Error404Alt"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));

// icons
const MingCuteIcons = React.lazy(
  () => import("../pages/ui/icons/MingCuteIcons")
);
const FeatherIcons = React.lazy(() => import("../pages/ui/icons/FeatherIcons"));
const MaterialSymbolIcons = React.lazy(
  () => import("../pages/ui/icons/MaterialSymbolIcons")
);

// Add import for SearchResults component
const SearchResults = React.lazy(() => import("../pages/admin/SearchResults"));
const AdminSearch = React.lazy(() => import("../pages/admin/Search/AdminSearch"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/home",
  name: "Dashboards",
  icon: "home",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/dashboard" />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      element: <Dashboard />,
      route: PrivateRoute,
    },
    {
      path: "/expense",
      name: "Expense",
      element: <Expense />,
      route: PrivateRoute,
    },
    {
      path: "/create-partners",
      name: "CreatePartners",
      element: <CreatePartners />,
      route: PrivateRoute,
    },
    {
      path: "/edit-partners/:id",
      name: "EditPartners",
      element: <EditPartners />,
      route: PrivateRoute,
    },
    {
      path: "/third-party-cost",
      name: "ThirdpartyCost",
      element: <ThirdPartycost />,
      route: PrivateRoute,
    },
    {
      path: "/create-cost",
      name: "ThirdpartyCost",
      element: <CreateThirdpartycost />,
      route: PrivateRoute,
    },
    {
      path: "/reports",
      name: "Reports",
      element: <Reports />,
      route: PrivateRoute,
    },
    {
      path: "/visibility-report-page",
      name: "Visibility Report",
      element: <VisibilityReportPage />,
      route: PrivateRoute,
    },
    {
      path: "/users",
      name: "Users",
      element: <Users />,
      route: PrivateRoute,
      children: [
        {
          path: "/user/create-user",
          element: <CreateUser />,
        },
        {
          path: "/user/edit-profile/:id",
          element: <UserInfoEditForm />,
        },
        {
          path: "/user/update-profile",
          element: <UpdateProfile />,
        },
      ],
    },
    {
      path: "/team-manger",
      name: "team-manager",
      element: <TeamManager />,
      route: PrivateRoute,
      children: [
        {
          path: "/team-manger/edit-profile/:id",
          element: <TeamManagereditForm />,
        },
      ],
    },
    {
      path: "/customers",
      name: "Customers",
      element: <Customers />,
      route: PrivateRoute,
      children: [
        {
          path: "/customer/create-customer",
          element: <CreateCustomer />,
        },
        {
          path: "/customers/payments",
          element: <CustomersPayments />,
        },
        {
          path: "/customer/edit-profile/:id",
          element: <CustomerInfoEditForm />,
        },
        {
          path: "/customer/view-profile/:id",
          element: <ViewCustomerProfile />,
        },
        {
          path: "/customer/invoice/:id",
          element: <ViewCustomerInvoice />,
        },
        {
          path: "/customer/view-profile/invoice/:id",
          element: <CustomerViewProfileInvoice />,
        },
        {
          path: "/my-invoice/:id",
          element: <ViewSingleCustomerInvoice />,
        },
        {
          path: "/customers/invoices",
          element: <AllCustomersInvoiceTable />,
        },
      ],
    },
    {
      path: "/edit-business-profile/",
      name: "Edit Business Profile",
      element: <EditBusinessProfile />,
      route: PrivateRoute,
    },
    {
      path: "/sale-representatives",
      name: "Sale Representatives",
      element: (
        <UserAuth allowedRoles={["admin"]}>
          <SaleReps />
        </UserAuth>
      ),
      route: PrivateRoute,
      children: [
        {
          path: "/sale-representative/sales-rep-create",
          element: <CreateSalesRep />,
          route: PrivateRoute,
        },
        {
          path: "/sale-representative/edit-profile/:user_id",
          element: <SaleRepsInfoEditForm />,
          route: PrivateRoute,
        },
        {
          path: "/sale-representative/view-profile/:user_id",
          element: <ViewSaleRepProfile />,
          route: PrivateRoute,
        },
        {
          path: "/salesReps/payments",
          element: <SalesRepsPayment />,
          route: PrivateRoute,
        },
        {
          path: "/salesReps/invoice/:id",
          element: <ViewInvoice />,
          route: PrivateRoute,
        },
        {
          path: "/salesReps/view-profile/invoice/:id",
          element: <SalesRepsViewProfileInvoice />,
          route: PrivateRoute,
        },
        {
          path: "/view/my-invoice/:id",
          element: <SalesRepsViewProfileInvoice />,
          route: PrivateRoute,
        },
        {
          path: "/salesReps/invoices",
          element: <SalesInvoices />,
          route: PrivateRoute,
        },
        {
          path: "/edit-profile",
          element: <SalesRepsEditProfile />,
          route: PrivateRoute,
        },
        {
          path: "/sales-reps/earnings",
          element: <Sales_Earnings />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/packages",
      name: "Packages",
      element: <Packages />,
      route: PrivateRoute,
      children: [
        {
          path: "/packages/create-package",
          element: (
            <UserAuth allowedRoles={["admin"]}>
              <CreatePackage />
            </UserAuth>
          ),
        },
        {
          path: "/packages/update-package/:id",
          element: (
            <UserAuth allowedRoles={["admin"]}>
              <UpdatePackage />
            </UserAuth>
          ),
        },
        { path: "/packages/package-details/:id", element: <PackageDetails /> },
        { path: "/my-packages", element: <CustomerPackage /> },

        { path: "/packages/subscriptions", element: <Subscription /> },
        { path: "payments", element: <Payment /> },
      ],
    },
    {
      path: "/faqs",
      name: "FAQs",
      element: <FAQs />,
      route: PrivateRoute,
      children: [
        {
          path: "/faqs/:id",
          element: (
            <UserAuth allowedRoles={["admin"]}>
              <UpdateFAQ />
            </UserAuth>
          ),
          route: PrivateRoute,
        },
        {
          path: "/faqs/create_new",
          element: (
            <UserAuth allowedRoles={["admin"]}>
              <CreateNewFAQ />
            </UserAuth>
          ),
          route: PrivateRoute,
        },
        {
          path: "/faqs/view_document/:id",
          element: <ViewFAQFile />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/documents",
      name: "Documents",
      element: <Documents />,
      route: PrivateRoute,
      children: [
        {
          path: "/documents/create_document",
          name: "Create Document",
          element: (
            <UserAuth allowedRoles={["admin"]}>
              <CreateDocumentForm />
            </UserAuth>
          ),
          route: PrivateRoute,
        },
        {
          path: "/documents/update_document/:id",
          name: "Update Document",
          element: (
            <UserAuth allowedRoles={["admin"]}>
              <UpdateDocumentForm />
            </UserAuth>
          ),
          route: PrivateRoute,
        },
        {
          path: "/documents/view_document/:id",
          name: "View Document",
          element: <ViewDocument />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/admin/search",
      name: "Global Search",
      element: (
        <UserAuth allowedRoles={["admin"]}>
          <AdminSearch />
        </UserAuth>
      ),
      route: PrivateRoute,
    },
    {
      path: "/admin/reports/search",
      name: "Search Results",
      element: (
        <UserAuth allowedRoles={["admin"]}>
          <SearchResults />
        </UserAuth>
      ),
      route: PrivateRoute,
    },
  ],
};

// pages
const customPagesRoutes = {
  path: "/pages",
  name: "Pages",
  icon: "pages",
  header: "Custom",
  children: [
    {
      path: "/error-404-alt",
      name: "Error - 404-alt",
      element: <Error404Alt />,
      route: PrivateRoute,
    },
  ],
};

// Customer
const customerRoutes: RoutesProps = {
  path: "/Customer",
  name: "Customer",
  icon: "pocket",
  header: "Elements",
  children: [
    {
      path: "/Customer",
      name: "Customer",
      children: [
        {
          path: "/Customer/profile",
          name: "Profile",
          route: PrivateRoute,
        },
        {
          path: "/my-invoices",
          name: "Single Customer Invoices",
          element: <CustomerSingleInvoice />,
          route: PrivateRoute,
        },
        {
          path: "/my-payments",
          name: "Single Customer Payments",
          element: <CustomerSinglePayment />,
          route: PrivateRoute,
        },
        {
          path: "/Customer/payments",
          name: "Payments",
          route: PrivateRoute,
        },
        {
          path: "/packages/subscriptions",
          name: "Subscription",
          route: PrivateRoute,
        },
        {
          path: "/Customer/upload",
          name: "Upload",
          route: PrivateRoute,
        },
      ],
    },
  ],
};
// Sales Reps
const salesrepsRoutes: RoutesProps = {
  path: "/SalesReps",
  name: "Sales Reps",
  roles: ["admin"],
  icon: "pocket",
  header: "Elements",
  children: [
    {
      path: "/SalesReps",
      name: "Customer",
      children: [
        {
          path: "/SalesReps/profile",
          name: "Profile",
          route: PrivateRoute,
        },
        {
          path: "/salesReps/customers",
          name: "All Customer",
          element: <SalesRepsCustomer />,
          route: PrivateRoute,
        },
        {
          path: "/my/invoices",
          name: "All Invoices",
          element: <SingleSalesRepsInvoice />,
          route: PrivateRoute,
        },
        {
          path: "/my/payments",
          name: "",
          element: <SingleSalesRepsPayment />,
          route: PrivateRoute,
        },
        {
          path: "/my/documents",
          name: "",
          element: <SalesRepDocuments />,
          route: PrivateRoute,
        },
        {
          path: "/salesReps/payments",
          name: "Payments",
          route: PrivateRoute,
        },
        {
          path: "/my/earnings",
          name: "My Earnings",
          element: <MyEarnings />,
          route: PrivateRoute,
        },
        {
          path: "/SalesReps/payments",
          name: "Reports",
          route: PrivateRoute,
        },
        {
          path: "/SalesReps/upload",
          name: "Upload",
          route: PrivateRoute,
        },
      ],
    },
  ],
};
// Team Manager
const teammanagerRoutes: RoutesProps = {
  path: "/TeamManager",
  name: "Team Manager",
  icon: "pocket",
  header: "Elements",
  children: [
    {
      path: "/TeamManager",
      name: "Team Manager",
      children: [
        {
          path: "/TeamManager/profile",
          name: "Profile",
          route: PrivateRoute,
        },
        {
          path: "/TeamManager/reports",
          name: "Repoorts",
          route: PrivateRoute,
        },
      ],
    },
  ],
};
// Setting
const settingsRoutes: RoutesProps = {
  path: "/Settings",
  name: "Settings",
  icon: "pocket",
  header: "Elements",
  children: [
    {
      path: "/Settings",
      name: "Settings",
      children: [
        {
          path: "/Settings/packages",
          name: "Packages",
          route: PrivateRoute,
        },
        {
          path: "/Settings/target",
          name: "Target",
          element: <Target />,
          route: PrivateRoute,
        },
        {
          path: "/Settings/performance-number",
          element: <PerformanceNumber />,
        },
        {
          path: "/Settings/commission",
          element: <Commission />,
        },
        {
          path: "/Settings/general-settings",
          name: "General Settings",
          element: <Generalsetting />,
          route: PrivateRoute,
        },
        {
          path: "/Settings/payments",
          name: "Payments",
          route: PrivateRoute,
        },
        {
          path: "/Settings/payment-gateway",  // Add the Payment Gateway route
          name: "Payment Gateway",
          element: <PaymentGateway />,
          route: PrivateRoute,
        },
        {
          path: "/Settings/reports",
          name: "Reports",
          route: PrivateRoute,
        },
      ],
    },
  ],
};

// ui
const uiRoutes: RoutesProps = {
  path: "/ui",
  name: "Components",
  icon: "pocket",
  header: "Elements",
  children: [
    {
      path: "/ui/icons",
      name: "Icons",
      children: [
        {
          path: "/ui/icons/mingcute",
          name: "Mingcute",
          element: <MingCuteIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/feather",
          name: "Feather",
          element: <FeatherIcons />,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/material",
          name: "Material Symbols",
          element: <MaterialSymbolIcons />,
          route: PrivateRoute,
        },
      ],
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/recover-password",
    name: "Recover Password",
    element: <RecoverPassword />,
    route: Route,
  },
  {
    path: "/auth/password-reset",
    name: "Password Reset",
    element: <PasswordReset />,
    route: Route,
  },
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/sales-reps-register",
    name: "SalesReps Register",
    element: <SalesRepsRegister />,
    route: Route,
  },

  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: "/registration/payment/:id",
    name: "payment",
    element: <RegistrationPayment />,
    route: Route,
  },
  {
    path: "/auth/register/packages",
    name: "packages",
    element: <RegPackages />,
    route: Route,
  },
  {
    path: "/auth/register/payment/:id",
    name: "Reg Payment",
    element: <RegPayment />,
    route: Route,
  },
  {
    path: "/auth/register/access-account",
    name: "Access New Account",
    element: <AccessNewAccount />,
    route: Route,
  },
  {
    path: "/landing-page",
    name: "Access New Account",
    element: <LandingPage />,
    route: Route,
  },
  {
    path: "*",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    element: <Maintenance />,
    route: Route,
  },
  {
    path: "/coming-soon",
    name: "Coming Soon",
    element: <ComingSoon />,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);
    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  customPagesRoutes,
  uiRoutes,
  teammanagerRoutes,
  customerRoutes,
  salesrepsRoutes,
  settingsRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  authProtectedFlattenRoutes,
  authProtectedRoutes,
  publicProtectedFlattenRoutes,
  publicRoutes,
};
